<template>
  <pro-layout
    :sider-width="120"
    :menus="menus"
    :collapsed="collapsed"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    prefixCls="my-test"
    v-bind="settings"
  > 
    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div class="logos">
        <img src="@/assets/newImage/logo-24.png" class="logos-icon" alt="" />
        <img src="@/assets/newImage/text.png" class="logos-text" alt="" />
      </div>
    </template>
    <template>
      <div
        v-if="toCrm"
        class="toCrm"
        @click="
          () => {
            getCRMAddress('/lead');
          }
        "
      >
        <img src="@/assets/toCrm.jpg" alt="" />
        <p>跟进线索</p>
      </div>
    </template>

    <!-- <template v-slot:menuHeaderRender>
      <div class="logos">
        <img src="@/assets/hg_pic_logo.png" alt="" />
      </div>
      <div class="exit">
        <popover placement="left" width="240" trigger="hover" popper-class="borderR10">
          <div class="exit-content">
            <div class="exit-content-top">
              <div>
                <img alt="头像" :src="avatarIMSrc" style="width: 48px; height: 48px; border-radius: 100%" />
              </div>
              <div class="exit-content-top-info">
                <div class="exit-content-top-name">
                  {{ userinfoserver.fullName }}
                </div>
                <div style="padding-top: 6px">
                  {{ userinfoserver.email }}
                </div>
              </div>
            </div>
            <ul class="exit-content-btm">
              <li @click="setAvator">
                <img src="@/assets/icons/service_icon_set.svg" alt="" style="width: 16px; height: 16px" />
                <span>设置</span>
              </li>
              <li @click="handleLogout">
                <img src="@/assets/icons/service_icon_exit.svg" alt="" style="width: 16px; height: 16px" />
                <span>退出登录</span>
              </li>
            </ul>
          </div>
          <div slot="reference">
            <img alt="头像" :src="avatarIMSrc" style="width: 48px; height: 48px; border-radius: 100%" />
          </div>
        </popover>
      </div>
    </template> -->

    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
    <template v-slot:headerContentRender>
      <div class="header-navwrap">
        <GlobalHeader></GlobalHeader>
      </div>
    </template>

    <!-- <setting-drawer :settings="settings" @change="handleSettingChange">
      <div style="margin: 12px 0;">
        This is SettingDrawer custom footer content.
      </div>
    </setting-drawer> -->
    <template v-slot:rightContentRender>
      <div class="exit">
        <popover placement="bottom" width="240" trigger="hover" popper-class="borderR10">
          <div class="exit-content">
            <div class="exit-content-top">
              <div class="exit-content-top-info">
                <div class="exit-email">
                  {{ userinfoserver.email }}
                </div>
                <div class="exit-content-top-name">
                  {{ userinfoserver.fullName }}
                </div>
              </div>
              <div class="avatar-set">
                <img alt="头像" :src="avatarIMSrc" style="width:32px height:32px;border-radius: 100%" />
              </div>
            </div>
            <div class="exit-content-btn">
              <div class="set-btn" @click="setAvator" style="cursor: pointer">设置</div>
              <a-divider type="vertical" style="background: rgba(31, 45, 61, 0.1)" />
              <div class="log-out" style="cursor: pointer" @click="handleLogout">退出</div>
            </div>
          </div>
          <div slot="reference">
            <img alt="头像" :src="avatarIMSrc" style="width: 32px; height: 32px; border-radius: 100%" />
          </div>
        </popover>
      </div>
    </template>
    <!-- custom footer / 自定义Footer -->
    <!-- <template v-slot:footerRender>
      <global-footer />
    </template> -->
    <template>
      <div class="layuiMain" id="watermark-parent">
        <audio preload="auto" hidden="hidden" ref="chatAudio">
          <source :src="chatIncoming" />
        </audio>
        <!-- <img
          v-if="is_expansion"
          src="@/assets/icons/service_icon_max.svg"
          alt=""
          class="expansion"
          @click="expansion"
        />
        <img v-else src="@/assets/icons/service_icon_min.svg" alt="" class="expansionRight" @click="expansion" /> -->

        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
      </div>
    </template>
  </pro-layout>
</template>
<script>
// IM
// eslint-disable-next-line import/no-duplicates
import { Notification } from 'element-ui';
// eslint-disable-next-line import/no-duplicates
import { Popover } from 'element-ui';
import { mapState, mapGetters } from 'vuex';
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout';
import { Modal } from 'ant-design-vue';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import watermark from 'watermark-dom';
import ResizeObserver from 'resize-observer-polyfill';
import { translateGroupSystemNotice } from '@/utils/IM/common';
import { ACTION } from '@/utils/IM//trtcCustomMessageMap';
import MTA from '@/utils/IM/mta';
import { getStudentInfo } from '@/api/headTeacher';
import { REMEMBERME, CONTENT_WIDTH_TYPE } from '@/store/mutation-types';
import { getArrEqualXWK, getArrIsSame, getCRMAddress } from '@/utils/util';

import { i18nRender } from '@/locales';
import defaultSettings from '@/config/defaultSettings';
// import RightContent from '@/components/GlobalHeader/RightContent';
// import GlobalFooter from '@/components/GlobalFooter';
import GlobalHeader from '@/components/GlobalHeader';
import { handlestopReceivingOrders } from '@/api/xiaowukong';

// eslint-disable-next-line camelcase
import pic_head_charge_lily from '@/assets/icons/pic_head_charge_lily@2x.png';
// eslint-disable-next-line camelcase
import pic_teacher from '@/assets/teacher.png';
import chatIncoming from '@/assets/IM/audio/chat-incoming.wav';

const session = storage.createStore(sessionStorage);

export default {
  name: 'BasicLayout',
  components: {
    // SettingDrawer,
    GlobalHeader,
    // LogoSvg
    Popover,
  },
  data() {
    return {
      getCRMAddress,
      userInfo: {},
      chatIncoming,
      sdkStudentList: [],
      // 学生接口数据
      studentsInfo: [],
      avatarIM: '',
      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false,
      },
      is_expansion: true,
      // 媒体查询
      query: {},
      // 是否手机模式
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(['totalUnreadCount']),
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      userinfoserver: (state) => state.user.userinfoserver,
      currentUserAvatar: (state) => state.userIM.currentUserAvatar,

      // IM
      studentName: (state) => state.conversation.studentName,
      currentUserProfile: (state) => state.userIM.currentUserProfile,
      currentConversation: (state) => state.conversation.currentConversation,
      conversationList: (state) => state.conversation.conversationList,
      videoCall: (state) => state.conversation.videoCall,
      audioCall: (state) => state.conversation.audioCall,
      isLogin: (state) => state.user.isLogin,
      isSDKReady: (state) => state.userIM.isSDKReady,
      isBusy: (state) => state.video.isBusy,
      userID: (state) => state.userIM.userID,
      userSig: (state) => state.userIM.userSig,
      sdkAppID: (state) => state.userIM.sdkAppID,
      searchStudentName: (state) => state.conversation.studentName,
    }),
    toCrm() {
      return storage.get('roles').some((i) => {
        return ['CLASS_ADMIN', 'TEACHING_STAFF', 'TEACHING_STAFF_ADMIN', 'HEAD_TEACHER_SUPER'].includes(i);
      });
    },

    avatarIMSrc() {
      return this.currentUserProfile.avatar ? this.currentUserProfile.avatar : this.currentUserAvatar;
    },
    roles() {
      const roles = storage.get('roles');
      const roleFLag = roles.find(
        (role) =>
          role === 'CLASS_ADMIN' ||
          role === 'HEAD_TEACHER_LEADER' ||
          role === 'X_WUKONG' ||
          role === 'TEACHING_STAFF' ||
          role === 'TEACHING_STAFF_ADMIN' ||
          role === 'HEAD_TEACHER_SUPER',
      );
      return roleFLag;
    },
    imgsrc() {
      // eslint-disable-next-line camelcase
      let src = pic_head_charge_lily;
      if (this.roles && this.roles === 'X_WUKONG') {
        // eslint-disable-next-line camelcase
        src = pic_head_charge_lily;
      } else {
        // eslint-disable-next-line camelcase
        src = pic_teacher;
      }
      return src;
    },
  },
  created() {
    // 处理侧栏收起状态
    // this.$watch('collapsed', () => {
    //   this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    // })
    // this.$watch('isMobile', () => {
    //   this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    // })
  },
  watch: {
    // 修改导航栏颜色
    $route: {
      handler(e) {
        let m = this.menus;
        if (!this.menus.length) {
          const routes = this.mainMenu.find((item) => item.path === '/');
          const menus = (routes && routes.children) || [];
          // 在菜单栏不要展示设置菜单
          m = menus.filter((item) => {
            return item.name !== 'setHome';
          });
        }
        this.menus = m.map((item) => {
          item.meta.icon = e.fullPath.includes(item.path) ? item.meta.selectedIcon : item.meta.unSelectIcon;
          return item;
        });
      },
      immediate: true,
    },

    totalUnreadCount(val) {
      const conversation = this.conversationList[0];

      // 最新的会话来都是第一个,第一个会话有未读数，并且就播放
      if (conversation?.unreadCount && this.currentConversation?.conversationID !== conversation?.conversationID) {
        this.$refs.chatAudio.play();
      }
    },
    isLogin(next) {
      if (next) {
        MTA.clickStat('link_two', { show: 'true' });
      }
    },
  },
  // beforeDestroy() {
  //   this.$bus.$off('handleStudentInfoSearch', this.handleStudentInfoSearch)
  // },

  mounted() {
    const obj = storage.get('userInfo');
    this.userInfo = obj;
    // 生成水印
    const pdfDom = document.getElementById('watermark-parent');
    // 监听元素的宽高，动态获取元素的高
    const myObserver = new ResizeObserver((entries) => {
      const height = pdfDom.scrollHeight;
      watermark.load({
        watermark_parent_height: height,
        watermark_txt: `${this.userInfo.fullName} ${this.userInfo.phone.substring(
          this.userInfo.phone.length - 4,
          this.userInfo.phone.length,
        )}`,
        watermark_parent_node: pdfDom,
        watermark_alpha: 0.08,
        watermark_fontsize: '14px',
        watermark_width: 290,
      });
    });
    myObserver.observe(pdfDom);

    const { userAgent } = navigator;
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed;
        setTimeout(() => {
          this.collapsed = !this.collapsed;
        }, 16);
      });
    }
    this.avatarIM = storage.get('avatarIM');
    // 只有班主任才在这里初始化IM
    if (!this.isSDKReady) {
      this.initListener();
      this.allMonitoring();
    }
  },
  updated() {},
  methods: {
    i18nRender,
    async loginOutSdk() {
      console.log(this.userID);
      if (this.roles && this.roles === 'X_WUKONG' && this.userID) {
        const params = {
          userId: this.userID,
        };
        await handlestopReceivingOrders(params);
      }
      await this.$store.dispatch('logout'); // 等退出SDK执行完了，在去执行退出账号操作
      this.$store.dispatch('Logout').then(() => {
        this.$store.commit('SET_ROUTERS', []);
        // 用 $router.push 原路由的权限等信息不会更新 会导致两个角色切换登陆时候 会保留上次路由权限
        // 临时方案 location.href 刷新页面 来更新路由信息
        // this.$router.push({ name: 'login' })this.$store.commit('SET_ROUTERS', []);
        this.$router.push({ name: 'binding' });
        // const url = `${window.location.origin}/user/login`;
        // window.location.href = url;
      });
    },
    handleLogout() {
      Modal.confirm({
        title: '退出登录',
        content: this.$t('layouts.usermenu.dialog.content'),
        okText: '退出',
        cancelText: '取消',
        centered: true,
        class: 'logout',
        onOk: () => {
          // 退出IM sdk

          this.loginOutSdk();
        },
        onCancel() {},
      });
    },
    setAvator() {
      this.$router.push({ path: '/setHome' });
    },
    expansion() {
      const menu = document.getElementsByClassName('ant-pro-sider-menu')[0];
      const sidemenu = document.getElementsByClassName('ant-pro-basicLayout-content')[0];
      this.$store.commit('SET_EXPAND', true);
      // eslint-disable-next-line no-unused-expressions
      menu.classList.contains('hidden-container')
        ? (sidemenu.style.marginLeft = '0')
        : (sidemenu.style.marginLeft = '-149px');
      // eslint-disable-next-line no-unused-expressions
      menu.classList.contains('hidden-container')
        ? this.$store.commit('SET_EXPAND', false)
        : this.$store.commit('SET_EXPAND', true);
      this.is_expansion = menu.classList.contains('hidden-container');
      // eslint-disable-next-line no-unused-expressions
      menu.classList.contains('hidden-container')
        ? menu.classList.remove('hidden-container')
        : menu.classList.add('hidden-container');
    },
    handleMediaQuery() {
      if (!this.is_expansion) {
        document.getElementsByClassName('ant-pro-sider-menu')[0].classList.add('hidden-container');
      }
    },
    handleCollapse(val) {
      this.collapsed = val;
    },
    handleSettingChange({ type, value }) {
      // eslint-disable-next-line no-unused-expressions
      type && (this.settings[type] = value);
      // eslint-disable-next-line default-case
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value;
          break;
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid;
          } else {
            this.settings.fixSiderbar = false;
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed;
          }
          break;
      }
    },
    handleStudentInfoSearch() {
      if (!this.userID) {
        return;
      }
      this.$store.commit('setSearchLoading', true);
      const params = {
        name: this.searchStudentName,
      };
      getStudentInfo(params).then((res) => {
        if (res.status === 200) {
          // 更新学生信息

          const studentList = res.data.content.list;
          this.studentsInfo = studentList;

          if (studentList.length === 0) {
            this.$store.commit('showMessage', {
              message: '没有找到该学生',
              type: 'warning',
            });

            // 清楚本地缓存的学生id信息
            this.studentsInfo = [];
            this.$store.commit('updateConversationList', []);
          } else {
            // 根据name滤掉
            this.sdkStudentList = session.get('sdkStudentList');
            const list = getArrEqualXWK(studentList, this.sdkStudentList);
            this.$store.commit('updateConversationList', list);
          }
        }
        this.$store.commit('setSearchLoading', false);
      });
    },

    initListener() {
      // 刷新浏览器,让IM不断开
      const obj = storage.get('userObj');
      // const obj = storage.get(REMEMBERME) ? storage.get('userObj') : session.get('userObj');
      if (obj) {
        // console.log(0);
        this.tim
          .login({
            userID: obj.userID,
            userSig: obj.userSig,
          })
          .then(() => {
            // console.log(1111);
            this.$store.commit({
              type: 'GET_USER_INFO',
              userID: obj.userID,
              userSig: obj.userSig,
              sdkAppID: obj.sdkAppID,
            });
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$store.commit('showMessage', {
              message: `登录失败：${error.message}`,
              type: 'error',
            });
          });
      }
    },
    // 初始化IM的监听事件
    allMonitoring() {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this);
      // console.log(this.isSDKReady);
      // SDK NOT READT
      this.tim.on(this.TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this);
      // 被踢出
      this.tim.on(this.TIM.EVENT.KICKED_OUT, this.onKickOut);
      // SDK内部出错
      this.tim.on(this.TIM.EVENT.ERROR, this.onError);
      // 收到新消息
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
      // 会话列表更新
      this.tim.on(this.TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onUpdateConversationList);
      // 群组列表更新
      // this.tim.on(this.TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
      // 网络监测
      this.tim.on(this.TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange);
      // 已读回执
      this.tim.on(this.TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer);
    },
    onReceiveMessage({ data: messageList }) {
      this.handleVideoMessage(messageList);
      this.handleAt(messageList);
      this.handleQuitGroupTip(messageList);
      this.handleCloseGroupLive(messageList);
      this.$store.commit('pushCurrentMessageList', messageList);
      this.$store.commit('pushAvChatRoomMessageList', messageList);
    },
    onError({ data }) {
      if (data.message !== 'Network Error') {
        this.$store.commit('showMessage', {
          message: data.message,
          type: 'error',
        });
      }
    },
    onMessageReadByPeer() {},
    onReadyStateUpdate({ name }) {
      const isSDKReady = name === this.TIM.EVENT.SDK_READY;
      this.$store.commit('toggleIsSDKReady', isSDKReady);
      // console.log(`${isSDKReady}dddd`);
      if (isSDKReady) {
        this.tim
          .getMyProfile()
          .then(({ data }) => {
            this.$store.commit('updateCurrentUserProfile', data);
          })
          .catch((error) => {
            this.$store.commit('showMessage', {
              type: 'error',
              message: error.message,
            });
          });
        this.$store.dispatch('getBlacklist');
        // 登录trtc calling
        this.trtcCalling.login({
          sdkAppID: this.sdkAppID,
          userID: this.userID,
          userSig: this.userSig,
        });
      }
    },
    kickedOutReason(type) {
      switch (type) {
        case this.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
          return '当前账号在其他网页登录，此网页已退出登录';
        case this.TIM.TYPES.KICKED_OUT_MULT_DEVICE:
          return '由于多设备登录，被踢出，请重新登录';
        case this.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
          return '由于 userSig 过期，被踢出，请重新登录';
        default:
          return '';
      }
    },
    checkoutNetState(state) {
      switch (state) {
        // case this.TIM.TYPES.NET_STATE_CONNECTED:
        //   return { message: '已接入网络', type: 'success' };
        case this.TIM.TYPES.NET_STATE_CONNECTING:
          return { message: '当前网络不稳定', type: 'warning' };
        case this.TIM.TYPES.NET_STATE_DISCONNECTED:
          return { message: '当前网络不可用', type: 'error' };
        default:
          return '';
      }
    },
    onNetStateChange(event) {
      // 不需要弹出接入网络
      if (event.data.state === this.TIM.TYPES.NET_STATE_CONNECTED) {
        return false;
      }
      this.$store.commit('showMessage', this.checkoutNetState(event.data.state));
    },
    onKickOut(event) {
      this.$store.commit('reset');
      // 清除登录的信息，免得刷新浏览器又自动登录
      session.remove('userObj');
      storage.remove('userObj');
      // 同时也清除登录的用户信息
      this.$confirm(`${this.kickedOutReason(event.data.type)}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: false,
        closeOnClickModal: false,
        type: 'warning',
        showClose: false,
      })
        .then(() => {
          this.$store.dispatch('Logout').then(() => {
            this.$store.commit('SET_ROUTERS', []);
            const url = window.location.host + this.$router.options.base + this.$router.history.current.path;
            window.location.href = url;
          });
        })
        .catch(() => {});
    },

    onUpdateConversationList(event) {
      const newStudentList = event.data.filter((item) => {
        return item.type === 'GROUP';
      });
      // console.log(newStudentList);
      this.$store.commit('updateConversationList', newStudentList);
      // 搜索群名要用的原始数据列表
      session.set('sdkStudentList', newStudentList);

      // const issame = getArrIsSame(newStudentList, this.sdkStudentList)
      // this.sdkStudentList = newStudentList
      // //存一下，防止从set进来，这个是空数组，搜索结果为空
      // session.set('sdkStudentList', newStudentList)
      // this.handleGetStudentInfo(issame)
    },

    // 根据GROUPid 去调接口 获取对应的学生信息 |  对比前后 event.data GROUPid 是否一样issame 不一样去调学生信息接口 (如果有筛选条件 也需要调学生信息接口)
    handleGetStudentInfo(issame) {
      // 现有数据的 groupIds
      if (!issame || this.studentName) {
        const params = {
          name: this.studentName,
        };
        // this.$store.commit('setSearchLoading', true)
        // 每次新的聊天数据 都会去请求学生信息接口 会导致接口太频繁 -》判断IM前后的群id是否一样 不一样才去调用学生信息的接口数据
        getStudentInfo(params).then((res) => {
          if (res.status === 200) {
            const studentList = res.data.content.list;
            this.studentsInfo = studentList;
            this.$store.state.conversation.totalCount = res.data.content.totalCount;
            let list = [];
            if (studentList.length === 0) {
              list = [];
            } else {
              // 根据groupID去
              list = getArrEqualXWK(studentList, this.sdkStudentList);
            }
            this.$store.commit('updateConversationList', list);
          }
        });
      } else {
        const list = getArrEqualXWK(this.studentsInfo, this.sdkStudentList);
        // console.log(list);
        this.$store.commit('updateConversationList', list);
      }
    },
    onUpdateGroupList(event) {
      // console.log(event.data);
      const newStudentList = event.data;

      const issame = getArrIsSame(newStudentList, this.sdkStudentList);
      // console.log(issame);
      this.sdkStudentList = newStudentList;
      // 存一下，防止从set进来，这个是空数组，搜索结果为空
      session.set('sdkStudentList', newStudentList);
      this.handleGetStudentInfo(issame);
    },
    onReceiveGroupSystemNotice(event) {
      const isKickedout = event.data.type === 4;
      const isCurrentConversation =
        `GROUP${event.data.message.payload.groupProfile.groupID}` === this.currentConversation.conversationID;
      // 在当前会话被踢，需reset当前会话
      if (isKickedout && isCurrentConversation) {
        this.$store.commit('resetCurrentConversation');
      }
      Notification({
        title: '新系统通知',
        message: translateGroupSystemNotice(event.data.message),
        duration: 3000,
        onClick: () => {
          const SystemConversationID = '@TIM#SYSTEM';
          this.$store.dispatch('checkoutConversation', SystemConversationID);
        },
      });
    },
    /**
     * 处理 @ 我的消息
     * @param {Message[]} messageList
     */
    handleAt(messageList) {
      // 筛选有 @ 符号的文本消息
      const atTextMessageList = messageList.filter(
        (message) => message.type === this.TIM.TYPES.MSG_TEXT && message.payload.text.includes('@'),
      );
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < atTextMessageList.length; i++) {
        const message = atTextMessageList[i];
        const matched = message.payload.text.match(/@\w+/g);
        if (!matched) {
          // eslint-disable-next-line no-continue
          continue;
        }
        // @ 我的
        if (matched.includes(`@${this.currentUserProfile.userID}`)) {
          // 当前页面不可见时，调用window.Notification接口，系统级别通知。
          if (this.$store.getters.hidden) {
            this.notifyMe(message);
          }
          Notification({
            title: `有人在群${message.conversationID.slice(5)}提到了你`,
            message: message.payload.text,
            duration: 3000,
          });
          this.$bus.$emit('new-messsage-at-me', {
            data: { conversationID: message.conversationID },
          });
        }
      }
    },
    selectConversation(conversationID) {
      if (conversationID !== this.currentConversation.conversationID) {
        this.$store.dispatch('checkoutConversation', conversationID);
      }
    },
    isJsonStr(str) {
      try {
        JSON.parse(str);
        return true;
      } catch {
        return false;
      }
    },
    handleVideoMessage(messageList) {
      const videoMessageList = messageList.filter(
        (message) => message.type === this.TIM.TYPES.MSG_CUSTOM && this.isJsonStr(message.payload.data),
      );
      if (videoMessageList.length === 0) return;
      const videoPayload = JSON.parse(videoMessageList[0].payload.data);
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_DIALING) {
        if (this.isBusy) {
          this.$bus.$emit('busy', videoPayload, videoMessageList[0]);
          return;
        }
        this.$store.commit('GENERATE_VIDEO_ROOM', videoPayload.room_id);
        this.selectConversation(videoMessageList[0].conversationID); // 切换当前会话页
        if (videoMessageList[0].from !== this.userID) {
          this.$bus.$emit('isCalled');
        }
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_SPONSOR_CANCEL) {
        this.$bus.$emit('missCall');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_REJECT) {
        this.$bus.$emit('isRefused');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_SPONSOR_TIMEOUT) {
        this.$bus.$emit('missCall');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_ACCEPTED) {
        this.$bus.$emit('isAccept');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_HANGUP) {
        this.$bus.$emit('isHungUp');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_LINE_BUSY) {
        this.$bus.$emit('isRefused');
      }
      if (videoPayload.action === ACTION.VIDEO_CALL_ACTION_ERROR) {
        this.$bus.$emit('isRefused');
      }
    },
    /**
     * 使用 window.Notification 进行全局的系统通知
     * @param {Message} message
     */
    notifyMe(message) {
      // 需检测浏览器支持和用户授权
      // eslint-disable-next-line no-empty
      if (!('Notification' in window)) {
      } else if (window.Notification.permission === 'granted') {
        this.handleNotify(message);
      } else if (window.Notification.permission !== 'denied') {
        window.Notification.requestPermission().then((permission) => {
          // 如果用户同意，就可以向他们发送通知
          if (permission === 'granted') {
            this.handleNotify(message);
          }
        });
      }
    },
    handleNotify(message) {
      const notification = new window.Notification('有人提到了你', {
        icon: 'https://webim-1252463788.file.myqcloud.com/demo/img/logo.dc3be0d4.png',
        body: message.payload.text,
      });
      notification.onclick = () => {
        window.focus();
        this.$store.dispatch('checkoutConversation', message.conversationID);
        notification.close();
      };
    },
    /**
     * 收到有群成员退群/被踢出的groupTip时，需要将相关群成员从当前会话的群成员列表中移除
     * @param {Message[]} messageList
     */
    handleQuitGroupTip(messageList) {
      // 筛选出当前会话的退群/被踢群的 groupTip
      const groupTips = messageList.filter((message) => {
        return (
          this.currentConversation.conversationID === message.conversationID &&
          message.type === this.TIM.TYPES.MSG_GRP_TIP &&
          (message.payload.operationType === this.TIM.TYPES.GRP_TIP_MBR_QUIT ||
            message.payload.operationType === this.TIM.TYPES.GRP_TIP_MBR_KICKED_OUT)
        );
      });
      // 清理当前会话的群成员列表
      if (groupTips.length > 0) {
        groupTips.forEach((groupTip) => {
          if (Array.isArray(groupTip.payload.userIDList) || groupTip.payload.userIDList.length > 0) {
            this.$store.commit('deleteGroupMemberList', groupTip.payload.userIDList);
          }
        });
      }
    },
    /**
     * 收到结束直播自定义消息，派发事件关闭组件
     * @param {Message[]} messageList
     */
    handleCloseGroupLive(messageList) {
      messageList.forEach((message) => {
        if (
          this.currentConversation.conversationID === message.conversationID &&
          message.type === this.TIM.TYPES.MSG_CUSTOM
        ) {
          let data = {};
          try {
            data = JSON.parse(message.payload.data);
          } catch (e) {
            data = {};
          }
          if (data.roomId && Number(data.roomStatus) === 0) {
            this.$bus.$emit('close-group-live');
          }
        }
      });
    },
  },
};
</script>

<style lang="less">
.ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-root{
height: calc(100vh - 64px - 110px);
}

.ant-pro-basicLayout-children-content-wrap,
.ant-pro-grid-content{
height: 100%;

}

.ant-layout-footer{
padding: 0;

}

.layuiMain{
height: 100%;
}

.toCrm{
  cursor: pointer;
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 20px;
  background-color: #20BD81;
  color: #EDFAF5;
  text-align: center;
  padding:10px 20px;
  border-radius: 4px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    margin: 0;
  }
  img{
    width: 30px;
    height: 30px;
  }
}

 //设置顶部导航的背景颜色
 .ant-pro-global-header{
   background: transparent;
   box-shadow: none
  // box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
 }
 .ant-pro-basicLayout .ant-layout-header:not(.ant-pro-top-menu){
background: transparent;
 }
.ant-pro-basicLayout-content{
  margin:0 16px 16px;
}
// .el-popover {
//   padding: 12px 12px 0;
// }
.borderR10 {
  border-radius:4px;
  padding:0;
  border:none;
  overflow: hidden;
}
// .totalUnreadCountIndex {
//   background-color: #ff5353;
//   padding: 2px 4px;
//   color: #fff;
//   font-size: 12px;
// }
 @import './BasicLayout.less';

// .ant-page-header.has-breadcrumb {
//   padding-top: 21px;
// }
// .ant-layout-header,
// .ant-layout-footer {
//   display: none;
// }
// .ant-pro-sider-menu {

// }
// .ant-layout .sidemenu {
//   padding-left: 100px !important;
// }
// .ant-breadcrumb {
//   span:first-child {
//     .ant-breadcrumb-link {
//       display: none;
//     }
//     .ant-breadcrumb-separator {
//       display: none;
//     }
//   }
// }
// .ant-menu {
//   position: relative;
//   padding-top: 15px;
//   .ant-menu-item {
//     height: 58px;
//     line-height: 58px;
//     font-size: 20px;
//     &::after {
//       border-right: none;
//     }
//   }
// }

// .ant-pro-sider-menu-sider.fix-sider-bar .ant-menu-root {
//   height: calc(100vh - 146px);
// }
.ant-pro-sider-menu-logo {
  width: 100%;
  // height: 168px;
  // padding-left: 0;
  // box-shadow: none !important;
  // background: #FF5353 !important;

.logos-icon{
  width: 24px;
  height: 24px;

}
.logos-text{
  width: 40px;
  height: 18px;
  margin-left: 10px;
}
}
.exit {
  width:32px;
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right:18px;
  overflow: hidden;
  font-size: 14px;
  color: #999;
  &:hover {
    span {
      color: #fff;
    }
    cursor: pointer;
  }
}
.exit-content {
  .avatar-set{
    width:32px;
    height:32px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  //  height: 182px;
  .exit-content-top {
    display: flex;
    font-size: 12px;
    color: #333333;
    margin-bottom: 20px;
    padding:12px 12px 0;
    .exit-content-top-info {
      flex:1;
      margin-right: 12px;
    }
    .exit-content-top-name {
        margin-top:6px;
        font-size: 12px;
        color: rgba(31, 45, 61, 0.4);
    }
    .exit-email{

      font-size: 14px;
      font-weight: 600;
      color: #1F2D3D;
    }
  }

}
.exit-content-btn{
  display: flex;
  background: rgba(31, 45, 61, 0.05);
  color: #1F2D3D;
  font-weight: 500;
  padding:7px 0;
  font-size: 13px;
  line-height: 22px;
    align-items: center;

}
// .ant-menu-item:nth-last-child(1) {
//   position: absolute;
//   left: 0;
//   bottom: 145px;
//   z-index: 99;
// }
.expansion {
  width: 40px;
  height: 20px;
  position: fixed;
  top: 120px;
  left: 112px;
  z-index: 50;
}
.expansionRight {
  width: 40px;
  height: 20px;
  position: fixed;
  top: 120px;
  left: -2px;
  z-index: 50;
}
.hidden-container {
  display: none;
}
.ant-layout-sider-children .ant-menu-inline .ant-menu-item a{
  width:70px;
  // height: 70px;
}
.header-navwrap{
  display: flex;
  align-items: center;
  height: 64px;
}
.set-btn,.log-out{
text-align: center;
flex: 1;
  &:hover{
    color:rgba(0, 178, 129, 1);
  }
}
</style>
