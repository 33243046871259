<template>
  <div class="header-nav">
    <a-icon class="arrow-left" @click="gohistory" v-if="this.$route.meta.permission.length === 0" type="arrow-left" />
    <a-breadcrumb>
      <a-breadcrumb-item v-for="item in tabs.meta.menu" :key="item.path">
         <router-link :to="session.get(item.path) || item.path">{{ item.title }}</router-link>

      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
const session = storage.createStore(sessionStorage);
export default {
  data() {
    return {
      session,
      current: ['mail'],
      tabs: [], // 切换表,
      currentPath: ''
    }
  },
  watch: {
    $route: function(to, from) {
      console.log(to)
      //支持地址栏带参数:D
      session.set(to.path,to.fullPath)
      if (from.meta.pMenuName !== to.meta.pMenuName || from.fullPath !== to.fullPath) {
        this.currentPath = to.path
        this.initTabs(to.meta.pMenuName)
      } else {
        this.current = [this.$route.path]
      }
    }
  },
  created() {
    const role = this.$route.meta.pMenuName
    this.currentPath = this.$route.path
   //新打开网页，会记住以前的存的路径，这里进来更新一下，学生详情那里会有问题
      session.set(this.currentPath,this.$route.fullPath)
    this.initTabs(role)
  },
  methods: {
    //不要通过地址栏带参数，要不然匹配不上路由
    initTabs(role) {
      console.log(role)
      const _this = this
      if (role === undefined) {
        _this.tabs = [
          {
            path: _this.$route.path,
            meta: _this.$route.meta
          }
        ]
        _this.current[0] = _this.$route.path
        return
      }
      _this.current[0] = _this.$route.path
      try {
      console.log( _this.$store.getters.addRouters[0])
        _this.$store.getters.addRouters[0].children.forEach(item => {
            console.log(role,item.name)

          if (role === item.name) {
          
            _this.tabs = item.children.find(childrenItem => {
              return childrenItem.path === this.currentPath
            })
            throw item
          }
        })
        console.log( _this.tabs)
      } catch (e) {}
      console.log(_this.tabs)
    },

    gohistory() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>

.header-nav {

  display: flex;
  justify-content: space-between;
  .ant-menu-horizontal {
    line-height: 24px;
    padding-top: 0;
    background-color: transparent;
    border-bottom: none;
    .arrow-left {
      i {
        font-size: 24px;
        color: #333;
      }
      padding: 0;
    }
    .ant-menu-item {
      height: 24px;
      line-height: 24px;
      font-size: 18px;
      color: #fff !important;
      a {
        color: #fff;
      }
      a:hover {
        color: #fff;
      }
    }
    .ant-menu-item-selected,
    .ant-menu-item:hover {
      // color: #333;
      border-bottom: 2px solid transparent;
    }
    .ant-menu-item-selected > a {
      color: #333 !important;
      font-weight: 600;
    }
  }
  .header-config {
    img {
      width: 32px;
      height: 32px;
      margin-left: 60px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .ant-breadcrumb {
    span {
      font-size: 14px;
      height: 25px;
      color: #1F2D3D;
    }
    .ant-breadcrumb-link {
      .router-link-active {
        font-weight: 600;
        color: #333333;
        line-height: 25px;
      }
    }
  }
}
</style>
