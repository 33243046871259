export const ACCESS_TOKEN = 'Access-Token'
export const REFRESH_ACCESS_TOKEN = 'refresh-Token'
export const USERNAME = 'username'
// 原登录保存的用户信息
export const USERINFO = 'userinfo'
// 浏览器返回的用户信息
export const USERINFOSERVER = 'userinfoserver'
export const UUID = 'UUID'
export const REMEMBERME = 'rememberMe'
export const ROLES = 'roles'

export const SIDEBAR_TYPE = 'sidebar_type'
export const TOGGLE_MOBILE_TYPE = 'is_mobile'
export const TOGGLE_NAV_THEME = 'nav_theme'
export const TOGGLE_LAYOUT = 'layout'
export const TOGGLE_FIXED_HEADER = 'fixed_header'
export const TOGGLE_FIXED_SIDEBAR = 'fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = 'content_width'
export const TOGGLE_HIDE_HEADER = 'auto_hide_header'
export const TOGGLE_COLOR = 'color'
export const TOGGLE_WEAK = 'weak'
export const TOGGLE_MULTI_TAB = 'multi_tab'
export const APP_LANGUAGE = 'app_language'

export const CURRENT_CONVERSATION_STUDENT = 'current_conversation_student'

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}

export const NAV_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
}
