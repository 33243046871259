<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <div class="service-main">
        <div class="service-logo">
          <img class="logo" src="@/assets/login/loginpic.png" alt="" />
        </div>
        <!-- <div class="service-logo">
          <img class="logo" src="@/assets/login/service_login_pic_logo.svg" alt="" />
          <img src="@/assets/login/service_login_pic.svg" alt="" />
        </div> -->
        <div class="viewMain">
          <!-- <div class="top">
            <div class="header">
              <div>
                <img src="~@/assets/loading-logo.png" class="logo" alt="logo">
              </div>
            </div>
          </div> -->
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'

export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  mounted() {
    document.body.classList.add('userLayout')
  },
  beforeDestroy() {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;
  background-image: url(~@/assets/login/service_login_pic_bg.svg);
  background-size: cover;
  display: flex;
  align-items: center;
  .container {
    width: 100%;
    a {
      text-decoration: none;
    }
    .service-main {
      width: 80%;
      min-width: 1120px;
      max-width: 1260px;
      margin: auto;
      background-color: #fff;
      border-radius: 30px;
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
      overflow: hidden;
    }
    .service-logo {
      display: inline-block;
      width: 50%;
      height: 100%;
      border-radius: 30px 0 0 30px;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .viewMain {
      float: right;
      width: 50%;
      padding: 40px 100px 0;
      background-color: #fff;
      border-radius: 0 30px 30px 0;
    }
    .login-main {
      min-width: 260px;
      max-width: 414px;
      margin: 0 auto;
    }
  }
}
</style>
