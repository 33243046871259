import { post, imgUpdatePut, Imgfetch, imgpost } from '@/api'
const BASE_URL = process.env.VUE_APP_API_IM_URL

const userApi = {
  Register: '/public/auth/v2/service_center/register',
  SendCode: '/public/auth/recover_password/step1',
  Reset_password: '/public/auth/recover_password/step2',
  actionUrl: '/api/admin/file/sts/public',
  ImgUpdate: '/api/user/set_portrait',
  resetEmail: '/api/acc/service_center/reset_email',
  setResetPassword: '/api/account/reset_password'
}

/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function register(parameter) {
  return post(BASE_URL, userApi.Register, parameter)
}
/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function getSmsCaptcha(parameter) {
  return post(BASE_URL, userApi.SendCode, parameter)
}

/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function resetPassword(parameter) {
  return post(BASE_URL, userApi.Reset_password, parameter)
}
/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function imgUpdate(parameter) {
  return post(BASE_URL, userApi.ImgUpdate, parameter)
}
/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function actionUrl(parameter) {
  return Imgfetch(BASE_URL, userApi.actionUrl, parameter)
}
/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function imgpostUrl(actionUrl, parameter) {
  return imgpost(actionUrl, parameter)
}
/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function resetEmail(parameter) {
  return post(BASE_URL, userApi.resetEmail, parameter)
}
/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function setResetPassword(parameter) {
  return post(BASE_URL, userApi.setResetPassword, parameter)
}
