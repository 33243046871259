import TRTCCalling from 'trtc-calling-js'

let options = {
  SDKAppID: process.env.VUE_APP_TIM_APP_ID 
}

const trtcCalling = new TRTCCalling(options)

// 4 无日志级别
trtcCalling.setLogLevel(4)

export default trtcCalling
