import { getLocationList } from "@/api/location";

/**
 * 替换底层数据源为原格式
 */
const getLegacyData = async () => {
  const { data: response } = await getLocationList();
  return {
    data: {
      content: response?.map((item) => ({
        areaCode: item.callingCode,
        label: item.countryZh,
        name: item.countryEn,
        value: item.iso2,
      })),
    },
  };
};
export { getLegacyData };

// new
// callingCode: '+56';
// capital: null;
// cityEn: null;
// cityZh: null;
// countryEn: 'Chile';
// countryZh: '智利';
// iso2: 'CL';
// iso3: 'CHL';
// lat: null;
// lng: null;
// population: null;
// stateEn: null;
// stateZh: null;
// timezone: null;

// old
// areaCode: '+93';
// label: '阿富汗';
// name: 'Afghanistan';
// value: 'AF';
