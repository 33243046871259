import { post } from '@/api'
const BASE_URL = process.env.VUE_APP_API_IM_URL

const userApi = {
  Login: '/public/email'
}

/**
 * @param  {[parameter]}
 * @return {[Promise]}
 */
export function login(parameter) {
  return post(BASE_URL, userApi.Login, parameter)
}
