// import { asyncRouterMap, constantRouterMap } from '@/config/router.config'
import { asyncRouterMapTeacher, constantRouterMap } from '@/router/router.config'
import { deepClone } from '@/utils/util'
import storage from 'store';

/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission(permission, route) {
  if (route.meta && route.meta.permission) {
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {

      flag = route.meta.permission.includes(permission[i])

      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}
// function filterAsyncRouter(routerMap, roles) {
//   const cloneRouter = deepClone(routerMap)
//   const accessedRouters = cloneRouter.map(route => {
//     if (route.children && route.children.length) {

//       route.children = route.children.filter(item => {
//         if (hasPermission(roles, item)) {

//           return true
//         }
//         return false
//       })
//     }
//     return route
//   })
//   console.log(accessedRouters)
//   accessedRouters[0].redirect = accessedRouters[0].children[0].redirect
//   return accessedRouters
// }
function filterAsyncRouter(routerMap, roles) {
  const cloneRouter = deepClone(routerMap)
  const accessedRouters = cloneRouter.map(route => {
    if (route.children && route.children.length) {
      route.children = route.children.filter(item => {
        if (hasPermission(roles, item)) {
          route.children.map((routeSub) => {
            if (routeSub.children && routeSub.children.length) {//处理子菜单的权限
              routeSub.children = routeSub.children.filter(itemSub => {
                if (hasPermission(roles, itemSub)) {
                  return true
                }
                return false
              })
            }
          })
          return true
        }
        return false
      })

    }

    return route
  })

  accessedRouters[0].redirect = accessedRouters[0].children[0].redirect

  return accessedRouters
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {

      return new Promise(resolve => {
        // const { roles } = { roles: ['TEACHER'] }

        const accessedRouters = filterAsyncRouter(asyncRouterMapTeacher, data.roles)

        accessedRouters[0].redirect = accessedRouters[0].children[0].redirect
        commit('SET_ROUTERS', accessedRouters)
        resolve()
      })
    }
  }
}

export default permission
