import storage from 'store'
import sessionStorage from 'store/storages/sessionStorage'
import { login } from '@/api/login'
import { resetPassword } from '@/api/register'

import { ACCESS_TOKEN, USERNAME, USERINFO, ROLES, REMEMBERME, USERINFOSERVER,REFRESH_ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
const session = storage.createStore(sessionStorage)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    // userinfoserver:JSON.parse(session.get(USERINFOSERVER)),
    userinfoserver: JSON.parse(localStorage.getItem('userinfoserver'))
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_USERINFOSERVER: (state, userinfoserver) => {
      state.userinfoserver = userinfoserver
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        const { email, password } = userInfo
        login({ email, password })
          .then(response => {
            const data = response.data

            //只有班主任和小悟空角色才能登录
            const roleArr = data.content.roles
            const roleFLag = roleArr.find(role => role === 'CLASS_ADMIN' || role === 'X_WUKONG' || role==='TEACHING_STAFF' || role==='TEACHING_STAFF_ADMIN' || role==='HEAD_TEACHER_SUPER' || role==='HEAD_TEACHER_LEADER')
            //只有角色对了，才去生成路由表
            if (roleFLag) {
              if (data.accountData !== null || !data.accountData.wkHeadImageUrl) {
                storage.set(REMEMBERME, userInfo.rememberMe)
                storage.set(ACCESS_TOKEN, data.content.token)
                storage.set(REFRESH_ACCESS_TOKEN, data.content.refreshToken);
                // if (userInfo.rememberMe) {
                //   storage.set(ACCESS_TOKEN, data.content.token, 7 * 24 * 60 * 60 * 1000)
                //   //也要记住im的登录信息
                // } else {
                //   session.set(ACCESS_TOKEN, data.content.token)
                // }
                storage.set(ROLES, data.content.roles)
                userInfo.repassword ? storage.set(USERINFO, JSON.stringify(userInfo)) : storage.remove(USERINFO)
                commit('SET_TOKEN', data.content.token)
              }
              // 保存用户名 等信息到本地存储 目前没有专门的用户信息接口 只要登录才能获得 存在问题如果后台修改后不能及时更新；
              // 上面的userInfo是用户登录信息 ;
              const parms = {
                fullName: data.content.fullName,
                email: data.content.email
              }
              storage.set(USERINFOSERVER, parms)
              commit('SET_USERINFOSERVER', parms)
            }

            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_ROLES', storage.get(ROLES))
        commit('SET_INFO', {
          name: storage.get(USERNAME)
        })
        commit('SET_NAME', { name: storage.get(USERNAME), welcome: welcome() })
        commit('SET_AVATAR', 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png')
        resolve()
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise(resolve => {
        console.log('退出了账号')
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_USERINFOSERVER', {})
        session.remove(ACCESS_TOKEN)
        storage.remove(ACCESS_TOKEN)
        session.remove(REFRESH_ACCESS_TOKEN);
        storage.remove(REFRESH_ACCESS_TOKEN);

        storage.remove(USERNAME)
        storage.remove(ROLES)
        storage.set(USERINFOSERVER)
        resolve()
      })
    },
    // 修改密码
    ResetPassword({ commit }, userInfo) {
      return new Promise(resolve => {
        resetPassword(userInfo)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            storage.remove(ACCESS_TOKEN)
            storage.remove(USERNAME)
            storage.remove(USERINFO)
            storage.remove(ROLES)
            resolve()
          })
          .catch(() => {
            resolve()
          })
          .finally(() => {})
      })
    }
  }
}

export default user
