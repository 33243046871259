const errorcode = [
  { code: 400, msg: '前端参数异常' },
  { code: 401, msg: '登录信息已过期，请重新登录' },
  { code: 403, msg: '访问权限不足' },
  { code: 422, msg: '验证错误' },
  { code: 428, msg: '强制修改密码' },
  { code: 500, msg: '服务器开小差了，请稍后尝试' }
]
export {
  errorcode
}
