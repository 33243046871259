import { post, fetch } from '@/api'
const BASE_URL = process.env.VUE_APP_API_IM_URL

const userApi = {
  // 待接入数据 ?serverId=test0
  toAccessList: '/api/server/list',
  // 接单 取消接单
  handleUpdateorder: '/api/server/change_state',
  // 小悟空加入群
  addGroupxiaowukong: '/api/server/join',
  // 退出登录
  // 发送自定义退出信息 /api/message/send_msg?userId=&groupId=1622691864185&type=RESULT_YES
  handleCustomizeExitInformation: '/api/message/send_msg',
  // 退群
  handleRefundGroup: '/api/server/resolved',
  // 退出登陆 关闭网页 停止接单
  stopReceivingOrders: '/public/login_out',
  // 在聊中 学生列表 （筛选学生信息）
  getStudentsInfo: '/api/server/group/student_msg'
}

export function getToAccessList(parameter) {
  const url = `${userApi.toAccessList}/${parameter.serverId}`
  return fetch(BASE_URL, url)
}
export function addGroupxiaowukong(parameter) {
  return post(BASE_URL, userApi.addGroupxiaowukong, parameter)
}
export function handleCustomizeExitInformation(parameter) {
  return fetch(BASE_URL, userApi.handleCustomizeExitInformation, parameter)
}
export function handleRefundGroup(parameter) {
  return post(BASE_URL, userApi.handleRefundGroup, parameter)
}
export function handleUpdateorder(parameter) {
  const url = `${userApi.handleUpdateorder}/${parameter.id}/${parameter.type}`
  return fetch(BASE_URL, url, parameter)
}
export function handlestopReceivingOrders(parameter) {
  return fetch(BASE_URL, `${userApi.stopReceivingOrders}/${parameter.userId}`)
}
export function getStudentsInfo(parameter) {
  // todu
  return post(BASE_URL, userApi.getStudentsInfo, parameter)
  // return fetch('http://localhost:3003/', 'users')
}
