import Vue from 'vue';

// base library
import {
  ConfigProvider,
  // Layout,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  // FormModel,
  Row,
  Col,
  Modal,
  Table,
  Tabs,
  Icon,
  Badge,
  Popover,
  Dropdown,
  List,
  Avatar,
  Breadcrumb,
  Steps,
  Spin,
  Menu,
  Drawer,
  Tooltip,
  Alert,
  Tag,
  // Divider,
  DatePicker,
  TimePicker,
  Upload,
  Progress,
  // Skeleton,
  Popconfirm,
  PageHeader,
  // Result,
  // Statistic,
  // Descriptions,
  Space,
  Timeline,
  Rate,
  message,
  notification,
  Cascader,
  TreeSelect,
  Tree,
} from 'ant-design-vue';
// import Viser from 'viser-vue'

// ext library
import VueCropper from 'vue-cropper';
import Dialog from '@/components/Dialog';
import MultiTab from '@/components/MultiTab';
import PageLoading from '@/components/PageLoading';
import PermissionHelper from '@/core/permission/permission';
import './directives/action';

Vue.use(ConfigProvider);
// Vue.use(Layout)
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Button);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Card);
Vue.use(Form);
// Vue.use(FormModel)
Vue.use(Row);
Vue.use(Col);
Vue.use(Modal);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Badge);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(List);
Vue.use(Avatar);
Vue.use(Breadcrumb);
Vue.use(Steps);
Vue.use(Spin);
Vue.use(Menu);
Vue.use(Drawer);
Vue.use(Tooltip);
Vue.use(Alert);
Vue.use(Tag);
// Vue.use(Divider)
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Upload);
Vue.use(Progress);
// Vue.use(Skeleton)
Vue.use(Popconfirm);
Vue.use(PageHeader);
// Vue.use(Result)
// Vue.use(Statistic)
// Vue.use(Descriptions)
Vue.use(Timeline);
Vue.use(Space);
Vue.use(Rate);
Vue.use(Tree);
Vue.use(TreeSelect);
Vue.use(Cascader);
Vue.prototype.$confirmAnt = Modal.confirm;
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;

// Vue.use(Viser)
Vue.use(Dialog); // this.$dialog func
Vue.use(MultiTab);
Vue.use(PageLoading);
Vue.use(PermissionHelper);
Vue.use(VueCropper);

// eslint-disable-next-line no-unused-expressions
process.env.NODE_ENV !== 'production' && '';
