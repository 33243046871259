import { getLocationList } from '@/api/location';
import { makeLocationNameTransformer } from './makeLocationNameTransformer';
import { systemLanguage } from '../consts';

const getCountryOptions = async (language = systemLanguage) => {
  const { data: response } = await getLocationList();
  const transformer = makeLocationNameTransformer(language);

  const countryOptions = response.map((item) => ({
    label: transformer(item, {
      zh: 'countryZh',
      en: 'countryEn',
    }),
    value: item.countryEn ?? '',
  }));

  return countryOptions;
};

export { getCountryOptions };
