// import router from './router'
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import NProgress from 'nprogress'; // progress bar
import store from './store';
import router from './router';
import '@/components/NProgress/nprogress.less'; // progress bar custom style
// import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil';
import { ACCESS_TOKEN, ROLES, REMEMBERME } from '@/store/mutation-types';
import { i18nRender } from '@/locales';

NProgress.configure({ showSpinner: false }); // NProgress Configuration
const session = storage.createStore(sessionStorage);
const allowList = ['login', 'register', 'material', 'binding']; // no redirect allowList
const loginRoutePath = '/user/login';
const defaultRoutePath = '/';
router.beforeEach((to, from, next) => {
  NProgress.start(); // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`);
  /* has token */
  // const _token = storage.get(REMEMBERME) ? storage.get(ACCESS_TOKEN) : session.get(ACCESS_TOKEN)
  const _token = storage.get(ACCESS_TOKEN);

  
  if (_token) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath });
      NProgress.done();
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then((res) => {
          // generate dynamic router
          store.dispatch('GenerateRoutes', { roles: storage.get(ROLES) }).then(() => {
            // 根据roles权限生成可访问的路由表
            // 动态添加可访问路由表
            router.addRoutes(store.getters.addRouters);
            // 请求带有 redirect 重定向时，登录自动重定向到该地址
            const redirect = decodeURIComponent(from.query.redirect || to.path);
            if (to.path === redirect) {
              // set the replace: true so the navigation will not leave a history record
              next({ ...to, replace: true });
            } else {
              // 跳转到目的路由
              next({ path: redirect });
            }
          });
        });
      } else {
        next();
      }
    }
  } else if (allowList.includes(to.name)) {
    // 在免登录名单，直接进入
    next();
  } else {
    // token失效跳转到飞书绑定
    const redirectUri = encodeURIComponent(`https://${window.location.host}/user/binding`);
    window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${redirectUri}&app_id=cli_a189c9d411f8d00c`;
    // next({ path: loginRoutePath });
    NProgress.done();
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
