import axios from 'axios';
import storage from 'store';
// import qs from 'qs'
import { message, Modal } from 'ant-design-vue';
import sessionStorage from 'store/storages/sessionStorage';
import router from '@/router/index';
import { ACCESS_TOKEN, REMEMBERME } from '@/store/mutation-types';
import { errorcode } from './errcode';


const BASE_URL = process.env.VUE_APP_API_IM_URL;

const session = storage.createStore(sessionStorage);
axios.defaults.timeout = 1200000;
axios.defaults.baseURL = '';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.request.use(
  (config) => {
    // const token = storage.get(REMEMBERME) ? storage.get(ACCESS_TOKEN) : session.get(ACCESS_TOKEN)
    const token = storage.get(ACCESS_TOKEN);


    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    message.error('服务器开小差了，请稍后尝试');
    return Promise.reject(error);
  },
);
// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config } = error.response

    if (config?.skipErrorHandler) {
      return error.response;
    }

    const showErrorMsg = (msg) => {
      const methodMap = {
        toast() {
          message.destroy();
          message.error(msg);
        },
        modal() {
          Modal.error({
            title: '错误提示',
            content: msg,
          })

        }
      }
      return methodMap[config?.showErrorType ?? 'toast']?.()

    }



    if (error?.response?.status === 422) {
      var errormeg;
      if (error.response.data.errors) {
        errormeg = error.response.data.errors[Object.keys(error.response.data.errors)[0]];
      } else {
        errormeg = error.response.data.message;
      }
      if (
        errormeg !== 'validation.serviceCenter.email.exist' &&
        errormeg !== 'validation.serviceCenter.teacher.notFound' &&
        errormeg !== 'User not found' &&
        errormeg !== 'teacher will power not found' &&
        errormeg !== 'Incorrect password' &&
        errormeg !== 'New password is the same as your old password'
      ) {
        showErrorMsg(errormeg)
      }
    } else if (error?.response?.status === 400) {
      if (error.response.data.errors) {
        errormeg = error.response.data.errors[Object.keys(error.response.data.errors)[0]];
      } else {
        errormeg = error.response.data.message;
      }
      if (errormeg !== 'Invalid target to retrieve user account!') {
        showErrorMsg(errormeg)
      }
    } else if (error?.response?.status === 401) {
      message.error('登录信息已过期，请重新登录');
      // 清缓存，以免用登录页面，直接跳到飞书绑定去
      localStorage.clear();
      session.clearAll();

      router.push({ name: 'binding' });
    } else {
      errorcode.map((item) => {
        if (error?.response?.status === item.code) {
          showErrorMsg(item.msg)
        }
      });
    }

    // blob下载文件，文件下载失败将blob的错误信息转换成json格式
    if (
      error?.response?.request?.responseType &&
      error?.response?.request?.responseType === 'blob' &&
      error?.response?.status === 422
    ) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const errData = JSON.parse(e.target.result);
          errormeg = errData.message;
          showErrorMsg(errormeg)
        };
        reader.readAsText(error.response.data);
      } catch (e) {
        console.log('出错');
      }
    }

    return Promise.reject(error);
  },
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch(BASE_URL, url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + url, {
        params,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function Imgfetch(BASE_URL, url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + url, { headers: { Authorization: `Bearer ${params.type}` } })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
// 以前的好的
export function post(BASE_URL, url, data, config) {
  return new Promise((resolve, reject) => {
    axios.post(BASE_URL + url, data, config).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
}

export function imgpost(url, data, responseType) {
  return new Promise((resolve, reject) => {
    axios({
      responseType: responseType || '', // 返回的文件类型，下载为Bolb类型
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      url,
      data,
    }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
}
/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(BASE_URL, url, data) {
  return new Promise((resolve, reject) => {
    axios.put(BASE_URL + url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
}
export function imgUpdatePut(BASE_URL, url, params) {
  return new Promise((resolve, reject) => {
    axios.put(BASE_URL + url, { data: params.data }, { headers: { Authorization: `Bearer ${params._token}` } }).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function DELETE(BASE_URL, url, data) {
  return new Promise((resolve, reject) => {
    axios.delete(BASE_URL + url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      },
    );
  });
}

export function download(BASE_URL, url, params, fileType, fileName = 'download') {
  url = BASE_URL + url;
  fileType = fileType || 'csv';
  const config = {
    params,
    // headers和responseType一定要在一起设置，否则会导致 获取到的二进制文件流 格式不正确
    headers: {
      'content-disposition': 'attachment;filename=total',
      'Content-Type': 'application/x-download;charset=utf-8',
    },
    responseType: 'blob', // 设置请求数据格式
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((err) => {
        resolve(err.data);
        if (!err) {
          return;
        }
        const url = window.URL.createObjectURL(err.data);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', `${fileName}.${fileType}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export default axios;
