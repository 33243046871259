import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'

// default router permission control
import permission from './modules/permission'
// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

//IM
import conversation from './modules/IM/conversation'
import group from './modules/IM/group'
import userIM from './modules/IM/user'
import video from './modules/IM/video'
import friend from './modules/IM/friend'
import xiaowukong from './modules/IM/xiaowukong'
import student from './modules/IM/student'

import blacklist from './modules/IM/blacklist'
import groupLive from './modules/IM/groupLive'
import { Message } from 'element-ui'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    conversation,
    group,
    friend,
    blacklist,
    userIM,
    video,
    groupLive,
    xiaowukong,
    student
  },

  state: {
    current: Date.now(), // 当前时间
    intervalID: 0,
    message: undefined
  },
  mutations: {
    startComputeCurrent(state) {
      state.intervalID = setInterval(() => {
        state.current = Date.now()
      }, 500)
    },
    stopComputeCurrent(state) {
      clearInterval(state.intervalID)
      state.intervalID = 0
    },
    showMessage(state, options) {
      if (state.message) {
        state.message.close()
      }
      state.message = Message({
        message: options.message,
        type: options.type || 'success',
        duration: options.duration || 2000,
        offset: 40
      })
    }
  },
  actions: {},
  getters
})
