import { getToAccessList } from '@/api/xiaowukong'
import storage from 'store';

import sessionStorage from 'store/storages/sessionStorage'
const session = storage.createStore(sessionStorage)
const wukongModules = {
  state: {
    // 待接入数据
    toAccessList:[],
    //是否有新的待接入会话
    toAccessFlag:false
  },
  getters: {

  },
  mutations: {
    updateToaccessList(state, newdata) {
      state.toAccessList = newdata
    },
    updateToAccessFlag(state,flag) {
      state.toAccessFlag = flag
    },
  },
  actions: {
    // 待接入数据
    handleToAccessList ({ commit, state }, products) {
      getToAccessList(products).then(
        res=>{
          // console.log('getToAccessList-res',res)
          if(res.status === 200 && res.data){
            console.log(9999)
            //存每次最新的待接入列表，跟前一次的数据做对比，有不一样的证明有新的接入
            session.set('originAccessList',res.data.content)
            commit("updateToaccessList", res.data.content)
           // commit("updateToAccessFlag", res.data.content)
          }
        }
      )
    },
  }
}

export default wukongModules
