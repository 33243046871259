// with polyfills
// update commom package
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { registerMicroApps, start } from 'qiankun';
import Vue from 'vue';
// import router from '@/router'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout';
import moment from 'moment';
import { VuePlugin } from 'vuera';

import * as Echarts from 'echarts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// import themePluginConfig from '../config/themePluginConfig'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import '@/core/lazy_use'; // use lazy load components
import '@/global.less';
import '@/permission'; // permission control
import '@/utils/filter'; // global filter

// 全局请求
import { DELETE, download, fetch, post, put } from '@/api/index';
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';
// window.umi_plugin_ant_themeVar = themePluginConfig.theme

// IM
import { teacherPosterRegister } from '@wk/bmp-webcomponents';
import { Descriptions, Divider, FormModel } from 'ant-design-vue';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  Loading,
  MessageBox,
  Row,
  Switch,
  TabPane,
  Tabs,
} from 'element-ui';
import TIM from 'tim-js-sdk';
import TRTCCalling from 'trtc-calling-js';
import TWebLive from 'tweblive';
import VueClipboard from 'vue-clipboard2';
import infiniteScroll from 'vue-infinite-scroll';
import { VueAxios } from '@/utils/request';
import store from '@/store/';
import router from '@/router';
import i18n from '@/locales';
import bootstrap from '@/core/bootstrap';
import App from '@/App.vue';
import './assets/IM/icon/iconfont.css';
import './assets/IM/icon/tim.css';
import Avatar from './components/IM/avatar.vue';
import tim from './tim';
import trtcCalling from './trtc-calling';
import './styles/index.less';

Vue.prototype.$echarts = Echarts; // global style

Vue.prototype.$post = post;
Vue.prototype.$fetch = fetch;
Vue.prototype.$put = put;
Vue.prototype.$delete = DELETE;
Vue.prototype.$download = download;

Vue.config.productionTip = false;
Vue.use(preview);
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios);
Vue.use(VuePlugin);
Vue.use(VueClipboard);

// use pro-layout components
Vue.component('pro-layout', ProLayout);
Vue.component('page-container', PageHeaderWrapper);
Vue.component('page-header-wrapper', PageHeaderWrapper);
teacherPosterRegister();

window.tim = tim;
window.TIM = TIM;
window.TRTCCalling = TRTCCalling;
window.trtcCalling = trtcCalling;
window.store = store;
Vue.prototype.$bus = new Vue(); // event Bus 用于无关系组件间的通信。
Vue.prototype.tim = tim;
Vue.prototype.TIM = TIM;
Vue.prototype.TWebLive = TWebLive;
Vue.prototype.$store = store;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.trtcCalling = trtcCalling;
Vue.prototype.TRTCCalling = TRTCCalling;
Vue.prototype.$moment = moment;
moment.locale('zh-cn');
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(VueClipboard);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Switch);
Vue.use(FormModel);
Vue.use(Divider);
Vue.use(Descriptions);
Vue.component('avatar', Avatar);

Vue.use(infiniteScroll);
new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: (h) => h(App),
  data: {
    Bus: new Vue(),
  },
}).$mount('#app');

// 埋点
const envs = {
  preview: 'test',
  production: 'production',
}
window.gatherer.WKGatherer({
  config: {
    env: envs[process.env.NODE_ENV],
  },
  data: {
    ak: 'educational',
  },
});

registerMicroApps([
  {
    name: 'reactApp',
    entry: process.env.VUE_APP_MICROAPP_URL,
    container: '#micro-container',
    activeRule: '/app-react',
  },
]);
// 启动 qiankun
start({});