const studentModules = {
  state: {
    studentList: [],
    currentStudentID: '',
    studentIdRoute: '',
    studentProfile: {},
    searchStudentName: '',
    studentPosterList: [],
  },
  getters: {},
  mutations: {
    updateStudentList(state, studentList) {
      state.studentList = studentList;
    },
    updatePosterStudentList(state, studentList) {
      state.studentPosterList = studentList;
    },
    updateCurrentStudentID(state, studentID) {
      state.currentStudentID = studentID;
    },
    setStudentIdRoute(state, studentID) {
      state.studentIdRoute = studentID;
    },
    updateStudentProfile(state, profile) {
      state.studentProfile = profile;
    },
    setStudentName(state, name) {
      state.searchStudentName = name;
    },
  },
  actions: {},
};

export default studentModules;
