import config from '@/config/defaultSettings'

export const setDocumentTitle = function(title) {
  document.title = title
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function() {
      setTimeout(function() {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}
export function classOutInTime(value) {
  let theTime = Number(value);
  let middle = 0;
  let hour = 0;
  if (theTime > 60) {
    middle = parseInt(theTime / 60, 10);
    theTime = parseInt(theTime % 60, 10);
    if (middle > 60) {
      hour = parseInt(middle / 60, 10);
      middle = parseInt(middle % 60, 10);
    }
  }
  let result = '';
  if (parseInt(theTime, 10) > 0) {
    result = `${parseInt(theTime, 10)}s`;
  }
  if (middle > 0) {
    result = `${parseInt(middle, 10)}m${result}`;
  }
  if (hour > 0) {
    result = `${parseInt(hour, 10)}h${result}`;
  }
  return result;
}
export const domTitle = config.title
