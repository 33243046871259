import TIM from 'tim-js-sdk'
// import COSSDK from 'cos-js-sdk-v5'
import TIMUploadPlugin from 'tim-upload-plugin'

// 初始化 SDK 实例
const tim = TIM.create({
  SDKAppID: process.env.VUE_APP_TIM_APP_ID
})

window.setLogLevel = tim.setLogLevel

// 无日志级别
tim.setLogLevel(4)

// 注册 cos
// tim.registerPlugin({ 'cos-js-sdk': COSSDK })
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
export default tim
